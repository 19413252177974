.toc {
  width: 200px;
}

.article {
  margin-left: 170px;
  margin-top: 20px;
  margin-right: 20px;
}

.ui.page.modals.dimmer.transition.visible.active > div {
  top: auto !important;
}

.addon {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.addon-item {
  width: 12em;
  box-shadow: grey 0px 0px 0.2em;
  border-radius: 0.2em;
  margin: 0.4em;
}

.addon-item.selected {
  background-color: lightskyblue;
  box-shadow: black 0px 0px 0.5em;
}

.addon-item-image {
  width: 12em;
  height: 12em;
}

.addon-item-name {
  font-weight: bold;
  padding: 0.5em 0.5em 0em 0.5em;
}
.addon-item-description {
  padding: 0em 0.5em 0.5em 0.5em;
}
