.device-usage-range-container {
  width: 250px;
}

.device-usage-range-picker {
  padding-right: 0 !important;
}

.device-usage-range-picker > div {
  width: 100% !important;
}

.device-report-summary-content {
  position: relative;
  text-align: center;
}

.device-report-summary-content.loading-spinner {
    width: 38px;
    height: 38px;
    margin: 0 auto;
}

.device-report-summary-content .dimmed-content {
  opacity: 0.4;
}

.device-usage-detail-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.device-usage-detail-header h1 {
  margin: 0 0 0 30px;
}

.device-usage-detail .back-button {
  cursor: pointer;
}

.device-usage-detail-table .detail-rows td {
  height: 67px !important;
}

.device-usage-detail-table td.graph-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;
}

.device-usage-detail-table td.graph-container .graph {
  position: absolute;
  top: 28px;
  background-color: #a6a6a6;
  height: 10px;
  min-width: 2px;
  z-index: 1;
}

.device-usage-detail-table td.graph-container .graph-marker-start,
.device-usage-detail-table td.graph-container .graph-marker-end {
  position: absolute;
  top: 23px;
  z-index: 3;
  pointer-events: none;
  background-color: #000;
  width: 2px;
  height: 20px;
}

.device-usage-detail-table .table-info {
  font-weight: normal !important;
}

.device-usage-detail-table .table-info-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.device-usage-detail-table .date-range {
  text-align: center !important;
}